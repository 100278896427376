html {
  height: 100%;
}

body {
  /* height: 100%; */
  overflow: hidden; /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
  margin: 0px; /*removes default style*/
  display: flex; /*enables flex content for its children*/
  box-sizing: border-box;
  /* font-family: 'Quicksand', sans-serif; */
  font-family: 'Muli', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.ui,
.ui.header {
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  line-height: 1.2;
}

.ui.form .field > label {
  color: #495057;
  font-weight: 450;
}

.ui.form .field input {
  color: #495057;
}

.ui.table thead tr > th {
  color: #495057;
  font-weight: 450;
}

.amber-ui {
  display: flex;
  width: 100%;
  height: 100%;
}

.amber-sidebar {
  display: flex;
  flex-direction: column;
  /* flex-shrink: 0; */
  /* height: 100%; */
  width: 244px;
  background-color: #1849a0;
  color: #efefef;
}

.amber-sidebar-item {
  display: flex;
  align-items: center;
  height: 46px;
  padding-left: 16px;
  padding-right: 4px;
}

.amber-sidebar-item:hover {
  background-color: #133778;
}

.amber-sidebar-item.active {
  background-color: #153f88;
}

.amber-content {
  height: 100%; /*allows both columns to span the full height of the browser window*/
  width: calc(100% - 244px);
  flex-direction: column; /*places the left and right headers above the bottom content*/
  display: inline-flex;
  flex-shrink: 0;
  flex-grow: 1; /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto; /*adds scroll to this container*/
  background-color: #f6f9fc;
}

.DayPickerInput {
  width: 100%;
}

.amber-selectable-item {
  background-color: #ffffff !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 0 !important;
}

.amber-selectable-item:hover {
  background-color: #f2f2f2 !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: right;
  vertical-align: middle;
}

.input-search {
  background-color: white !important;
  border-color: white !important;
}

input {
  box-sizing: border-box;
  width: 100%;
  padding: 6px;
  margin-left: -8px;
  background-color: transparent;
  outline-width: 0;
  border-width: 2px;
  border-radius: 3px;
  border-color: transparent;
  border-style: solid;
}

input:hover {
  background-color: #e8e9ee;
}

input:focus {
  box-sizing: border-box;
  background-color: transparent;
  border-color: #0049c5;
}

input.title {
  font-size: 2rem;
}

div.field {
  box-sizing: border-box;
  margin-bottom: 8px;
  /* border: 2px solid blue; */
  /* background-color: violet; */
}

div.field > div.label {
  box-sizing: border-box;
  color: #5e6c84;
  text-transform: uppercase;
  font-size: 12px;
}

div.field > div.control > input {
  font-size: 1rem;
  margin-bottom: 24px;
}


.sidebar-map {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  
  margin: 12px;
  border-radius: 4px;
}

.map-container {
  height: 400px;
  width: '60vw';
  margin-bottom: 2rem;
}

.card-box {
  position: relative;
  color: #fff;
  padding: 20px 10px 40px;
  margin: 20px 0px;
}
.card-box:hover {
  text-decoration: none;
  color: #f1f1f1;
}
.card-box:hover .icon i {
  font-size: 100px;
  transition: 1s;
  -webkit-transition: 1s;
}
.card-box .inner {
  padding: 5px 10px 0 10px;
}
.card-box h3 {
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 8px 0;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}
.card-box p {
  font-size: 15px;
}
.card-box .icon {
  position: absolute;
  top: auto;
  bottom: 5px;
  right: 5px;
  z-index: 0;
  font-size: 72px;
  color: rgba(0, 0, 0, 0.15);
}
.card-box .card-box-footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  text-align: center;
  padding: 3px 0;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  text-decoration: none;
}
.card-box:hover .card-box-footer {
  background: rgba(0, 0, 0, 0.3);
}
.bg-blue {
  background-color: #00c0ef !important;
}
.bg-green {
  background-color: #00a65a !important;
}
.bg-orange {
  background-color: #f39c12 !important;
}
.bg-red {
  background-color: #d9534f !important;
}